import React, { useState } from "react";
import { useHistory } from "react-router-dom";
import { Form, Input, Button, message, DatePicker, Spin } from "antd";
import "dayjs/locale/ru";
import locale from "antd/es/date-picker/locale/ru_RU";
import BackRouteButton from "src/common/components/BackRouteButton";

import "./index.css";

export default ({ createAnalysis, profile }) => {
  const [form] = Form.useForm();
  const history = useHistory();
  const [loading, setLoading] = useState(false);

  const onFinish = async (values) => {
    setLoading(true);
    values.creatorId = profile._id;

    const result = await createAnalysis(values);
    if (result.success) {
      form.resetFields();
      message.success("Анализ успешно записан!");
      history.goBack();
    } else {
      message.error(result.message || "Не удалось сохранить анализ!");
      setLoading(false);
    }
  };

  return (
    <div className="common-container">
      <Spin spinning={loading}>
        <BackRouteButton />
        <h4 className="common-title--large">Записать анализ</h4>
        <Form
          requiredMark={false}
          layout="vertical"
          form={form}
          onFinish={onFinish}
        >
          <Form.Item
            label="Дата:"
            name="date"
            rules={[
              {
                required: true,
                message: "Пожалуйста, выберите дату анализа!",
              },
            ]}
          >
            <DatePicker
              size="small"
              locale={locale}
              format="DD.MM.YYYY"
              style={{
                width: "50%",
              }}
            />
          </Form.Item>
          <Form.Item label="Данные по муке:" name="flour">
            <Input.TextArea />
          </Form.Item>
          <Form.Item label="Данные по пшенице:" name="wheat">
            <Input.TextArea />
          </Form.Item>

          <Form.Item>
            <Button type="primary" block size="medium" htmlType="submit">
              Записать
            </Button>
          </Form.Item>
        </Form>
      </Spin>
    </div>
  );
};
