import React, { useEffect, useState } from "react";
import { useHistory, useLocation } from "react-router-dom";
import {
  Form,
  Input,
  Select,
  Button,
  message,
  InputNumber,
  DatePicker,
  Space,
  Card,
  Table,
  Spin,
} from "antd";

import "dayjs/locale/ru";
import locale from "antd/es/date-picker/locale/ru_RU";
import BackRouteButton from "src/common/components/BackRouteButton";

const { Option } = Select;

export default ({ paymentCounteragentToCounter, profile, counters }) => {
  const [form] = Form.useForm();
  const history = useHistory();
  const location = useLocation();

  const [loading, setLoading] = useState(true);
  const [order, setOrder] = useState(false);

  const onFinish = async (values) => {
    setLoading(true);
    values.creatorId = profile._id;
    values.title = "Оплата заказа";

    const { _id, title } =
      counters.find((c) => c._id === values.counter1) || {};

    values.counter1 = { id: _id, title };

    values.to = values.counter1.id;
    values.from = order.counteragent.id;
    values.counteragent = order.counteragent;
    values.order = {
      id: order._id,
      serial: order.serial,
    };

    const result = await paymentCounteragentToCounter(values);

    if (result.success) {
      form.resetFields();
      message.success("Оплата принята!");
      history.goBack();
    } else {
      message.error(result.message || "Не удалось принять оплату!");
      setLoading(false);
    }
  };

  const columnsForModal = [
    {
      title: <span className="column-title-font">Наименование</span>,
      dataIndex: "title",
      key: "title",
      render: (text) => <span className="column-content-font">{text}</span>,
    },
    {
      title: <span className="column-title-font">Вес</span>,
      dataIndex: "weight",
      key: "weight",
      render: (text) => <span className="column-content-font">{text} кг</span>,
    },
    {
      title: <span className="column-title-font">Цена</span>,
      dataIndex: "price",
      key: "price",
      render: (text) => <span className="column-content-font">{text} руб</span>,
    },
  ];

  useEffect(() => {
    var order = location.state.order;

    order.counteragentName = `${order.counteragent.firm || ""} ${
      order.counteragent.firstname || ""
    } ${order.counteragent.lastname || ""}`;

    setOrder(order);
    setLoading(false);
  }, []);

  return (
    <div className="common-container">
      <BackRouteButton />
      <h4 className="common-title--large">Оплатить заказ</h4>
      <Spin spinning={loading}>
        <Card size="small" title={`Заказ №${order.serial}`}>
          <span className="column-title-font">Покупатель:</span>
          <span className="column-content-font">{order.counteragentName}</span>
          <br />
          <br />
          <Table
            dataSource={order.instances}
            columns={columnsForModal}
            pagination={false}
            size="small"
          />

          <div className="common-modal__info-block">
            <div className="common-modal__info-container">
              <span className="column-title-font">Общий вес:</span>
              <span className="column-content-font">
                {order.totalWeight} кг
              </span>
            </div>

            <div className="common-modal__info-container">
              <span className="column-title-font">Итого:</span>
              <span className="column-content-font">{order.totalSum} руб.</span>
            </div>
            <div className="common-modal__info-container">
              <span className="column-title-font">Оплачено:</span>
              {order.paid ? (
                <span className="column-title-font" style={{ color: "green" }}>
                  Да
                </span>
              ) : (
                <span className="column-content-font" style={{ color: "red" }}>
                  Нет
                </span>
              )}
            </div>
            <div className="common-modal__info-container">
              <span className="column-title-font">Всего оплачено:</span>
              {order.cancelled ? (
                <span className="column-title-font" style={{ color: "red" }}>
                  Отменен
                </span>
              ) : (
                <span className="column-content-font">
                  {order.totalSumPaid} руб.
                </span>
              )}
            </div>
            <div className="common-modal__info-container">
              <span className="column-title-font">Отгружено:</span>
              {order.shipped ? (
                <span className="column-title-font" style={{ color: "green" }}>
                  Да
                </span>
              ) : (
                <span className="column-content-font" style={{ color: "red" }}>
                  Нет
                </span>
              )}
            </div>
            {order.comment && (
              <div className="common-modal__info-container">
                <span className="column-title-font">Комментарий:</span>
                <span className="column-content-font">{order.comment}</span>
              </div>
            )}
          </div>
        </Card>
        <h3 className="common-modal-title">Данные платежа</h3>
        <Form
          requiredMark={false}
          layout="vertical"
          form={form}
          onFinish={onFinish}
        >
          <Space.Compact block size="small">
            <Form.Item
              style={{ width: "50%", margin: "0px" }}
              name="sum"
              rules={[
                {
                  required: true,
                  message: "Введите сумму платежа",
                },
              ]}
            >
              <InputNumber style={{ width: "100%" }} placeholder="Сумма" />
            </Form.Item>
            <Form.Item
              style={{ width: "40%", margin: "0px" }}
              name="type"
              rules={[
                {
                  required: true,
                  message: "Пожалуйста, выберите способ оплаты!",
                },
              ]}
            >
              <Select
                style={{ width: "100%" }}
                placeholder="Способ оплаты"
                optionFilterProp="children"
              >
                <Option value="наличные">
                  <h4 className="option-title-font">наличные</h4>
                </Option>
                <Option value="банковский счёт">
                  <h4 className="option-title-font">банковский счёт</h4>
                </Option>
                <Option value="перевод на карту">
                  <h4 className="option-title-font">перевод на карту</h4>
                </Option>
                <Option value="карта">
                  <h4 className="option-title-font">карта</h4>
                </Option>
              </Select>
            </Form.Item>
          </Space.Compact>
          <Form.Item
            label="Дата платежа"
            name="date"
            rules={[
              {
                required: true,
                message: "Пожалуйста, выберите дату!",
              },
            ]}
          >
            <DatePicker
              size="small"
              locale={locale}
              format="DD.MM.YYYY"
              style={{
                width: "50%",
              }}
            />
          </Form.Item>
          <Form.Item
            label="Касса/Счёт для зачисления"
            name="counter1"
            rules={[
              {
                required: true,
                message: "Выберите кассу/счёт для зачисления!",
              },
            ]}
          >
            <Select
              placeholder="Касса/счёт"
              size="small"
              optionFilterProp="children"
            >
              {counters &&
                counters.map((counter) => (
                  <Option key={counter._id} value={counter._id}>
                    <h4 className="option-title-font">{counter.title}</h4>
                  </Option>
                ))}
            </Select>
          </Form.Item>

          <Form.Item label="Комментарий" name="comment">
            <Input.TextArea />
          </Form.Item>

          <Form.Item>
            <Button type="primary" block size="medium" htmlType="submit">
              Принять оплату
            </Button>
          </Form.Item>
        </Form>
      </Spin>
    </div>
  );
};
