import React, { useState } from "react";
import {
  Form,
  DatePicker,
  Button,
  message,
  Spin,
  Modal,
  Table,
  Radio,
  Tabs,
  Alert,
} from "antd";
import locale from "antd/es/date-picker/locale/ru_RU";
import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";
import BackRouteButton from "src/common/components/BackRouteButton";

dayjs.extend(utc);

export default ({ createReport, report }) => {
  const [loading, setLoading] = useState(false);
  const [reportType, setReportType] = useState("single");
  const [form] = Form.useForm();
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [selectedGroup, setSelectedGroup] = useState("product");
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);

  const onFinish = async (values) => {
    setLoading(true);

    let startDateValue, endDateValue;

    if (reportType === "single") {
      const selectedDate = values.selectedDate;
      startDateValue = dayjs(selectedDate).startOf("day").toISOString();
      endDateValue = dayjs(selectedDate).endOf("day").toISOString();
    } else {
      const startSelectedDate = values.startDate;
      const endSelectedDate = values.endDate;
      startDateValue = dayjs(startSelectedDate).startOf("day").toISOString();
      endDateValue = dayjs(endSelectedDate).endOf("day").toISOString();
    }

    setStartDate(startDateValue);
    setEndDate(endDateValue);

    const result = await createReport({
      startDate: startDateValue,
      endDate: endDateValue,
    });

    if (result.success) {
      message.success("Отчет успешно создан!");
      setIsModalVisible(true);
    } else {
      message.error(result.message || "Не удалось создать отчет!");
    }

    setLoading(false);
  };

  const handleCancel = () => {
    setIsModalVisible(false);
    setSelectedGroup("product");
  };

  const modalTitle =
    reportType === "single"
      ? `Отчет за ${dayjs(startDate).format("DD.MM.YYYY")}`
      : `Отчет за период: ${dayjs(startDate).format("DD.MM.YYYY")} - ${dayjs(
          endDate
        ).format("DD.MM.YYYY")}`;

  const columns =
    selectedGroup === "product"
      ? [
          {
            title: <span className="column-title-font">Наименование</span>,
            dataIndex: "title",
            key: "title",
            render: (text) => (
              <span className="column-content-font">{text}</span>
            ),
          },
          {
            title: <span className="column-title-font">Произведено</span>,
            dataIndex: "totalProduced",
            key: "totalProduced",
            render: (text) => (
              <span className="column-content-font">{text} кг</span>
            ),
          },
          {
            title: <span className="column-title-font">Продано</span>,
            dataIndex: "totalSold",
            key: "totalSold",
            render: (text) => (
              <span className="column-content-font">{text} кг</span>
            ),
          },
        ]
      : [
          {
            title: <span className="column-title-font">Наименование</span>,
            dataIndex: "title",
            key: "title",
            render: (text) => (
              <span className="column-content-font">{text}</span>
            ),
          },
          {
            title: <span className="column-title-font">Переработано</span>,
            dataIndex: "totalProcessed",
            key: "totalProcessed",
            render: (text) => (
              <span className="column-content-font">{text} кг</span>
            ),
          },
          {
            title: <span className="column-title-font">Куплено</span>,
            dataIndex: "totalBought",
            key: "totalBought",
            render: (text) => (
              <span className="column-content-font">{text} кг</span>
            ),
          },
        ];

  const salaryColumns = [
    {
      title: <span className="column-title-font">ФИО</span>,
      dataIndex: "name",
      key: "name",
      render: (text, record) => (
        <span className="column-content-font">
          {`${record.firstname} ${record.lastname} ${record.additionalName || ""}`}
        </span>
      ),
    },
    {
      title: <span className="column-title-font">Всего заработано </span>,
      dataIndex: "totalProfit",
      key: "totalProfit",
      render: (text) => <span className="column-content-font">{text} руб</span>,
    },
  ];

  return (
    <div className="common-container">
      <Spin spinning={loading}>
        <BackRouteButton />
        <h4 className="common-title--large">Создать отчёт</h4>

        <Form form={form} layout="vertical" onFinish={onFinish}>
          <Form.Item label="Тип отчета:">
            <Radio.Group
              onChange={(e) => setReportType(e.target.value)}
              value={reportType}
            >
              <Radio value="single">Один день</Radio>
              <Radio value="range">Диапазон дат</Radio>
            </Radio.Group>
          </Form.Item>

          {reportType === "single" ? (
            <Form.Item
              label="Выберите день:"
              name="selectedDate"
              rules={[{ required: true, message: "Выберите дату!" }]}
            >
              <DatePicker
                size="small"
                locale={locale}
                format="DD.MM.YYYY"
                style={{ width: "100%" }}
              />
            </Form.Item>
          ) : (
            <>
              <Form.Item
                label="Начальная дата:"
                name="startDate"
                rules={[
                  { required: true, message: "Выберите начальную дату!" },
                ]}
              >
                <DatePicker
                  size="small"
                  locale={locale}
                  format="DD.MM.YYYY"
                  style={{ width: "100%" }}
                />
              </Form.Item>
              <Form.Item
                label="Конечная дата:"
                name="endDate"
                rules={[{ required: true, message: "Выберите конечную дату!" }]}
              >
                <DatePicker
                  size="small"
                  locale={locale}
                  format="DD.MM.YYYY"
                  style={{ width: "100%" }}
                />
              </Form.Item>
            </>
          )}
          {reportType === "range" && (
            <Alert
              description="Конечная дата включается в отчёт."
              type="info"
              showIcon
              style={{ marginBottom: "16px" }}
            />
          )}
          <Form.Item>
            <Button type="primary" block size="medium" htmlType="submit">
              Создать отчет
            </Button>
          </Form.Item>
        </Form>

        {report && (
          <Modal
            title={<h3 className="common-modal-title">{modalTitle}</h3>}
            open={isModalVisible}
            onCancel={handleCancel}
            footer={null}
            width={800}
          >
            <div className="custom-modal-content">
              <Tabs
                activeKey={selectedGroup}
                onChange={(key) => setSelectedGroup(key)}
              >
                <Tabs.TabPane tab="Продукция" key="product">
                  <Table
                    pagination={false}
                    dataSource={report["product"]}
                    columns={columns}
                  />
                </Tabs.TabPane>
                <Tabs.TabPane tab="Сырьё" key="rawMaterial">
                  <Table
                    pagination={false}
                    dataSource={report["rawMaterial"]}
                    columns={columns}
                  />
                </Tabs.TabPane>
                <Tabs.TabPane tab="Зарплаты" key="salaries">
                  <Table
                    pagination={false}
                    dataSource={report["salaries"]}
                    columns={salaryColumns}
                  />
                </Tabs.TabPane>
              </Tabs>
            </div>
          </Modal>
        )}
      </Spin>
    </div>
  );
};
