import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import {
  Form,
  Input,
  Select,
  Button,
  message,
  InputNumber,
  DatePicker,
  Space,
  Typography,
  Spin,
} from "antd";
import "dayjs/locale/ru";
import locale from "antd/es/date-picker/locale/ru_RU";
import BackRouteButton from "src/common/components/BackRouteButton";
import { MinusCircleOutlined, PlusOutlined } from "@ant-design/icons";

import "./index.css";

const { Option } = Select;
const { Title, Text } = Typography;
export default ({ initialRawMaterials, profile, storehouse }) => {
  const [form] = Form.useForm();
  const history = useHistory();

  const [loading, setLoading] = useState(false);
  const [rawMaterials, setRawMaterials] = useState([]);
  const [instances, setInstances] = useState(form.getFieldValue("instances"));

  const onFinish = async (values) => {
    setLoading(true);
    values.creatorId = profile._id;
    const { totalWeight, totalSum } = getTotalWeightAndSum(values.instances);
    values.totalWeight = totalWeight;
    values.totalSum = totalSum;

    if (values.instances.length === 0) {
      message.error("Добавьте хотя бы одну позицию!");
      return;
    }

    // Add instances details
    values.instances = values.instances.map((instance) => {
      const selectedInstance = rawMaterials.find(
        (inst) => inst._id === instance.id
      );
      return {
        id: selectedInstance._id,
        title: selectedInstance.title,
        weight: instance.weight,
        price: instance.price,
      };
    });

    const result = await initialRawMaterials(values);
    if (result.success) {
      form.resetFields();
      message.success("Ввод начальных остатков произведен!");
      history.goBack();
    } else {
      message.error(result.message || "Не удалось ввести начальные остатки!");
      setLoading(false);
    }
  };
  const updateTotalWeightAndSum = () => {
    const instancesList = form.getFieldValue("instances");
    const { totalWeight, totalSum } = getTotalWeightAndSum(instancesList);
    form.setFieldsValue({
      totalWeight,
      totalSum,
    });
  };

  const getTotalWeightAndSum = (instancesList) => {
    return (instancesList || []).reduce(
      (acc, item) => {
        const weight = parseFloat(item.weight) || 0;
        const price = parseFloat(item.price) || 0;
        acc.totalWeight += weight;
        acc.totalSum += Number((weight * price).toFixed(2));
        return acc;
      },
      { totalWeight: 0, totalSum: 0 }
    );
  };

  const handleInstanceSelect = (value, field) => {
    const instancesList = form.getFieldValue("instances");

    const isDuplicate = instancesList.some(
      (instance) =>
        instance.id === value &&
        instance !== form.getFieldValue(["instances", field.name])
    );

    if (isDuplicate) {
      message.error("Данный вид сырья уже добавлен в список.");
      form.setFieldsValue({
        instances: instancesList.map((instance, index) =>
          index === field.name
            ? {
                id: null,
                weight: null,
                price: null,
              }
            : instance
        ),
      });
    }
  };

  const handleProductFilter = (input, option) => {
    const product = rawMaterials.find((p) => p._id === option.value);

    return product.title.toLowerCase().includes(input.toLowerCase());
  };

  const renderinstancesList = (fields, operation) => {
    return (
      <>
        <Title level={5}>Начальные остатки сырья</Title>
        {fields.map((field, index) => (
          <div className="order-instance-container" key={field.key}>
            <Text style={{ width: "5%", margin: "0px" }} strong>
              {index + 1}
            </Text>
            <Space.Compact
              block
              size="small"
              key={field.key}
              direction="vertical"
            >
              <Space.Compact block size="small">
                <Form.Item
                  {...field}
                  name={[field.name, "id"]}
                  key={[field.key, "id"]}
                  style={{ width: "80%", margin: "0px" }}
                  rules={[
                    {
                      required: true,
                      message: "Выберите сырье",
                    },
                  ]}
                >
                  <Select
                    showSearch
                    placeholder="Выберите сырье"
                    filterOption={handleProductFilter}
                    onSelect={(value) => handleInstanceSelect(value, field)}
                  >
                    {rawMaterials.map((instance) => (
                      <Option key={instance._id} value={instance._id}>
                        <h4 className="option-title-font">{instance.title}</h4>
                      </Option>
                    ))}
                  </Select>
                </Form.Item>
              </Space.Compact>
              <Space.Compact block size="small">
                <Form.Item
                  {...field}
                  style={{ width: "40%", margin: "0px" }}
                  name={[field.name, "weight"]}
                  key={[field.key, "weight"]}
                  rules={[
                    {
                      required: true,
                      message: "Введите вес",
                    },
                  ]}
                >
                  <InputNumber
                    style={{ width: "100%" }}
                    placeholder="Вес"
                    onChange={updateTotalWeightAndSum}
                  />
                </Form.Item>
                <Form.Item
                  style={{ width: "40%", margin: "0px" }}
                  {...field}
                  name={[field.name, "price"]}
                  key={[field.key, "price"]}
                  rules={[
                    {
                      required: true,
                      message: "Введите цену",
                    },
                  ]}
                >
                  <InputNumber
                    style={{ width: "100%" }}
                    placeholder="Цена"
                    onChange={updateTotalWeightAndSum}
                  />
                </Form.Item>
                <Form.Item
                  key={[field.name, "remove"]}
                  style={{ margin: "0px" }}
                  {...field}
                >
                  <MinusCircleOutlined
                    style={{ marginLeft: "4px", color: "#ff4d4f" }}
                    onClick={() => operation.remove(field.name)}
                  />
                </Form.Item>
              </Space.Compact>
            </Space.Compact>
          </div>
        ))}
        <Button
          block
          size="small"
          type="dashed"
          onClick={() => operation.add()}
          icon={<PlusOutlined />}
        >
          Добавить сырье
        </Button>
      </>
    );
  };

  useEffect(() => {
    if (storehouse) {
      var rawMaterials = storehouse.rawMaterialsCategories.flatMap(
        (category) => category.instances || []
      );

      setRawMaterials(rawMaterials);
    }
  }, [storehouse]);

  useEffect(() => {
    setInstances(form.getFieldValue("instances"));
  }, [form]);

  useEffect(() => {
    const calculations = getTotalWeightAndSum(instances);
    form.setFieldsValue({
      totalWeight: calculations.totalWeight,
      totalSum: calculations.totalSum,
    });
  }, [instances]);

  return (
    <div className="common-container">
      <Spin spinning={loading}>
        <BackRouteButton />
        <h4 className="common-title--large">
           Введите начальные остатки пшеницы/сырья
        </h4>
        <Form
          requiredMark={false}
          layout="vertical"
          form={form}
          onFinish={onFinish}
        >
          <Form.Item
            label="Дата"
            name="date"
            rules={[
              {
                required: true,
                message: "Пожалуйста, выберите дату!",
              },
            ]}
          >
            <DatePicker
              size="small"
              locale={locale}
              format="DD.MM.YYYY"
              style={{
                width: "50%",
              }}
            />
          </Form.Item>

          <Form.List
            initialValue={[
              {
                id: null,
                weight: null,
                price: null,
              },
            ]}
            name="instances"
          >
            {(fields, operation) => renderinstancesList(fields, operation)}
          </Form.List>
          <Space.Compact
            block
            size="small"
            style={{
              marginTop: "20px",
            }}
          >
            <div
              style={{
                display: "flex",
                flexDirection: "column",
              }}
            >
              <Text>Общий вес</Text>
              <Form.Item name="totalWeight">
                <InputNumber readOnly />
              </Form.Item>
            </div>
            <div
              style={{
                display: "flex",
                flexDirection: "column",
              }}
            >
              <Text>Общая сумма</Text>
              <Form.Item name="totalSum">
                <InputNumber readOnly />
              </Form.Item>
            </div>
          </Space.Compact>

          <Form.Item label="Комментарий" name="comment">
            <Input.TextArea />
          </Form.Item>

          <Form.Item>
            <Button type="primary" block size="medium" htmlType="submit">
              Создать
            </Button>
          </Form.Item>
        </Form>
      </Spin>
    </div>
  );
};
