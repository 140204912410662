import React, { useState, useEffect } from "react";
import { useHistory, useLocation } from "react-router-dom";
import {
  Form,
  Input,
  Button,
  message,
  InputNumber,
  DatePicker,
  Spin,
} from "antd";
import dayjs from "dayjs";
import "dayjs/locale/ru";
import locale from "antd/es/date-picker/locale/ru_RU";
import BackRouteButton from "src/common/components/BackRouteButton";

import "./index.css";

export default ({ editRepair, profile, repairs }) => {
  const location = useLocation();
  const [form] = Form.useForm();
  const history = useHistory();
  const [loading, setLoading] = useState(false);
  const [repair, setRepair] = useState(false);

  const onFinish = async (values) => {
    setLoading(true);
    values._id = repair._id;
    values.creatorId = profile._id;

    const result = await editRepair(values);
    if (result.success) {
      form.resetFields();
      message.success("Ремонт успешно изменён!");
      history.goBack();
    } else {
      message.error(result.message || "Не удалось изменить ремонт!");
      setLoading(false);
    }
  };

  useEffect(() => {
    if (repairs) {
      const id = location.state.repair._id;
      for (var repair of repairs) {
        if (repair._id === id) {
          setRepair(repair);
          break;
        }
      }
    }
  }, [repairs]);

  useEffect(() => {
    if (repair) {
      var formattedDate = dayjs(repair.date).format("DD.MM.YYYY");
      formattedDate = dayjs(formattedDate, "DD.MM.YYYY");

      form.setFieldsValue({
        date: formattedDate,
        repairTime: repair.repairTime,
        scope: repair.scope,
        title: repair.title,
        replacedParts: repair.replacedParts,
        comment: repair.comment,
      });
      setLoading(false);
    }
  }, [repair, form]);
  return (
    <div className="common-container">
      <Spin spinning={loading}>
        <BackRouteButton />
        <h4 className="common-title--large">Изменить ремонт</h4>
        <Form
          requiredMark={false}
          layout="vertical"
          form={form}
          onFinish={onFinish}
        >
          <Form.Item
            label="Дата:"
            name="date"
            rules={[
              {
                required: true,
                message: "Пожалуйста, выберите дату записи!",
              },
            ]}
          >
            <DatePicker
              size="small"
              locale={locale}
              format="DD.MM.YYYY"
              style={{
                width: "50%",
              }}
            />
          </Form.Item>
          <Form.Item name="repairTime" label="Часов затрачено на ремонт:">
            <InputNumber
              size="small"
              style={{
                width: "10%",
              }}
            />
          </Form.Item>
          <Form.Item
            label="Мельница или узел, где производили ремонт:"
            name="scope"
          >
            <Input />
          </Form.Item>

          <Form.Item label="Название ремонта:" name="title">
            <Input />
          </Form.Item>
          <Form.Item label="Замененные детали:" name="replacedParts">
            <Input.TextArea />
          </Form.Item>

          <Form.Item label="Комментарии, информация о ремонте" name="comment">
            <Input.TextArea />
          </Form.Item>

          <Form.Item>
            <Button type="primary" block size="medium" htmlType="submit">
              Записать
            </Button>
          </Form.Item>
        </Form>
      </Spin>
    </div>
  );
};
